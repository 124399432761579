// import Vue from 'vue'
import { createStore } from 'vuex'
import * as Sentry from "@sentry/vue";
const fb = require('./../firebaseConfig.js')

// handle page reload
fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile')
    store.dispatch('fetchActivities')
    Sentry.setUser({ id: user.uid, email: user.email });
  }
})

export const store = new createStore({
  state: {
    currentUser: null,
    userProfile: null,
    activities: null,
    stats: null,
    sharedData: null,
    sharedActivities: null,
    isError: null
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setActivities(state, val) {
      state.activities = val
    },
    setSharedData(state, val) {
      state.sharedData = val
    },
    setSharedActivities(state, val) {
      state.sharedActivities = val
    },
    setIsError(state, val) {
      state.isError = val
    },
  },
  actions: {
    clearData({ commit }) {
      commit('setCurrentUser', null)
      commit('setUserProfile', null)
      commit('setActivities', null)
      commit('setStats', null)
      commit('setSharedData', null)
      commit('setSharedActivities', null)
      commit('setIsError', null)
    },
    fetchUserProfile({ commit, state }) {
      return fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
        commit('setUserProfile', res.data())
        return res.data()
      }).catch(err => {
        console.log(err)
        commit('setIsError', err.message)
      })
    },
    fetchActivities({commit, state}) {
      fb.activitiesCollection.where("uid", "==", state.currentUser.uid).orderBy("date", "desc")
      .get()
      .then(function(querySnapshot) {
          let activitiesArray = []
          querySnapshot.forEach(doc => {
              let score = doc.data()
              score.id = doc.id
              activitiesArray.push(score)
          })

          commit('setActivities', activitiesArray)
      })
      .catch(err => {
          console.log(err)
          commit('setIsError', err.message)
      })
    },
    fetchSharedData({commit, state}) {
      fb.usersCollection.doc(state.currentUser.uid).collection('shares')
      .get()
      .then(function(querySnapshot) {
          let sharedDataArray = []
          querySnapshot.forEach(doc => {
              let user = doc.data()
              user.id = doc.id
              sharedDataArray.push(user)
          })
          commit('setSharedData', sharedDataArray)
      })
      .catch(err => {
          console.log(err)
          commit('setIsError', err.message)
      })
    },
    fetchSharedActivities({commit}, params) {
      console.log(params.id)
      fb.activitiesCollection.where("uid", "==", params.id).orderBy("date", "desc")
      .get()
      .then(function(querySnapshot) {
          let sharedActivitiesArray = []
          querySnapshot.forEach(doc => {
              let sharedActivity = doc.data()
              sharedActivity.id = doc.id
              sharedActivitiesArray.push(sharedActivity)
          })

          commit('setSharedActivities', sharedActivitiesArray)
      })
      .catch(err => {
          console.log(err)
          commit('setIsError', err.message)
      })
    },
  },
  modules: {}
})