import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// firebase init goes here
const config = {
  apiKey: "AIzaSyCEz34gQSUXa0qEX-F8vslSTEsXAJkK1MM",
  authDomain: "simple-stats-golf.firebaseapp.com",
  projectId: "simple-stats-golf",
  storageBucket: "simple-stats-golf.appspot.com",
  messagingSenderId: "929552891798",
  appId: "1:929552891798:web:f8d21cb164cf102f5ca7b7"
}

firebase.initializeApp(config)

if (location.hostname === "localhost") {
  firebase.firestore().useEmulator("localhost", 8081);
  firebase.auth().useEmulator('http://localhost:9099/');
}

// firebase utils
const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser

// firebase collections
const usersCollection = db.collection('users')
const activitiesCollection = db.collection('activities')

export {
    db,
    auth,
    currentUser,
    usersCollection,
    activitiesCollection
}