<template>
  <div>
    <label :for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">{{ label }}</label>
  <div class="relative flex items-center max-w-[4rem] text-gray-900 text-sm">
    <div class="select-none border py-1.5 px-4 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-l-md" @click="decrease">
      -
    </div>

    <input class="border border-gray-300 p-1.5 text-center outline-none text-gray-900 text-sm" type="text" :value="localModelValue" :name="name" @input="$emit('update:modelValue', $event.target.value)" />

    <div class="select-none border py-1.5 px-4 cursor-pointer bg-gray-100 hover:bg-gray-200 rounded-r-md" @click="increase">
      +
    </div>
  </div>
</div>
</template>

<script>
  export default {
    data() {
      return {
        number: 0,
      };
    },
    computed: {
      localModelValue: {
        get() {
            return this.modelValue
        },
        set(newValue) {
            this.$emit('update:modelValue', newValue)
        },
      }
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    emits: ['update:modelValue'],
    methods: {
      increase() {
        this.localModelValue++;
      },
      decrease() {
        if (this.localModelValue >= 1) {
          this.localModelValue--;
        }
      },
    },
  };
</script>