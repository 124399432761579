<template>
  <div v-if="!activities || !userProfile" class="bg-gray-900 min-h-screen text-white flex items-center justify-center">
    <svg class="animate-spin h- w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
  </div>
  <div v-else class="bg-gray-900 min-h-screen"> 
  <div class="max-w-2xl mx-auto">
    <!-- Global notification live region, render this permanently at the end of the document -->
    <div aria-live="assertive" class="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50">
      <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
        <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
        <transition enter-active-class="transform ease-out duration-300 transition" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="showNotification !== false" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
          <div class="flex items-start">
          <div class="flex-shrink-0">
          <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" />
          </div>
          <div class="ml-3 w-0 flex-1 pt-0.5">
          <p class="text-sm font-medium text-gray-900">Activity saved successfully!</p>
          <p class="mt-1 text-sm text-gray-500">Your performance stats have also been updated.</p>
          </div>
          <div class="ml-4 flex flex-shrink-0">
          <button type="button" @click="showNotification = false" class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span class="sr-only">Close</span>
          <XMarkIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          </div>
          </div>
          </div>
        </div>
      </transition>
      </div>
    </div>

    <div v-if="updateExists" class="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8 z-10">
      <div class="pointer-events-auto flex items-center justify-between gap-x-6 bg-indigo-700 px-6 py-2.5 sm:rounded-xl sm:py-2 sm:pl-4 sm:pr-3.5">
        <p class="text-xs leading-6 text-white">
          <a @click="refreshApp" href="#">
            <strong class="font-semibold">New Updates Available</strong><svg viewBox="0 0 2 2" class="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true"><circle cx="1" cy="1" r="1" /></svg>Refresh&nbsp;<span aria-hidden="true">&rarr;</span>
          </a>
        </p>
      </div>
    </div>
    
    <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50" @close="resetDialog">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
  <form @submit.prevent class="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl max-w-md">
                    <div class="h-0 flex-1 overflow-y-auto">
                      <div class="bg-gray-800 px-4 py-6 sm:px-6">
                        <div class="flex items-center justify-between">
                          <DialogTitle class="text-base font-semibold leading-6 text-white">
                            <span v-if="formStatus == 'add'">Add Activity</span>
                            <span v-if="formStatus == 'edit'">Edit activity</span>
                          </DialogTitle>
                          <div class="ml-3 flex h-7 items-center">
                            <button
                              @click="resetDialog"
                              class="relative rounded-md bg-gray-800 text-gray-200 hover:text-white focus:outline-none"
                            >
                              <span class="absolute -inset-2.5" />
                              <span class="sr-only">Close panel</span>
                              <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        <!-- <div class="mt-1">
                          <p class="text-sm text-indigo-300">
                            Take a minute to note down how your activity went.
                          </p>
                        </div> -->
                      </div>


                      <div class="flex flex-1 flex-col justify-between">
                        <div class="divide-y divide-gray-200 px-4 sm:px-6">
                          <div class="space-y-6 pb-5 pt-6">

            
                <div>
                  <!-- <label for="location" class="block text-sm font-medium leading-6 text-gray-900">Activity Type</label> -->
                  <select id="location" name="type" required v-model="type" class="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option selected="">Tournament Round</option>
                    <option>Practice Round</option>
                    <option>Practice</option>
                  </select>
                </div>
                <div>
               <!--  <label
              htmlFor="description"
              class="block text-sm font-medium leading-6 text-gray-900"
              >
              Journal your thoughts and feelings about this activity.
              </label> -->
              <div class="mt-2">
              <textarea
              id="description"
              name="description"
              rows="3"
              :required="type == 'Practice'"
              minlength="5"
              v-model="notes"
              placeholder="How'd it go? Journal your thoughts and feelings about this activity."
              class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              </div>
            </div>
                
              <div>
              <!-- <label
              htmlFor="description"
              class="block text-sm font-medium leading-6 text-gray-900"
              >
              Date
              </label> -->
              <div class="relative mt-2">
                <div class="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                  <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z"/>
                  </svg>
                </div>
                <input id="datepicker" type="date" v-model="date" required class="bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Select date" />
              </div>
              </div>
              <div v-if="type == 'Tournament Round' || type == 'Practice Round'" class="space-y-6 border-t border-gray-200 py-6">
              <h3>Round Stats</h3>
              <div class="flex gap-x-3">
                  <input
                  placeholder="What score did you shoot?"
                  type="number"
                  name="score"
                  v-model="score"
                  :required="type != 'Practice'"
                  id="score"
                  class="block rounded-md w-2/3 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                  <div>
                  <!-- <label for="location" class="block text-sm font-medium leading-6 text-gray-900">Activity Type</label> -->
                  <select id="holesPlayed" name="holesPlayed" v-model="holesPlayed" class="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                    <option value="18" selected="">18 holes</option>
                    <option value="9">9 holes</option>
                  </select>
                </div>
                </div>
                <NumberInput name="bogeysCount" label="How many bogeys did you make on par 5's?" v-model.number="bogeysCount"></NumberInput>
                <NumberInput name="bogeys150Count" label="How many bogeys did you make when you had less than 150 yards for your approach?" v-model.number="bogeys150Count"></NumberInput>
                <NumberInput name="doubleBogeysCount" label="How many double bogey's or higher did you make?" v-model.number="doubleBogeysCount"></NumberInput>
                <NumberInput name="failedChips" label="How many easy chips did you not get up and down?" v-model.number="failedChips"></NumberInput>
                <NumberInput name="threePutts" label="How many 3 putts did you have?" v-model.number="threePutts"></NumberInput>
                <div class="border-t border-gray-200 py-6">
                <NumberInput name="mentalErrors" label="How many shots were you not fully committed to your intention?" v-model.number="mentalErrors"></NumberInput>
              </div>
              <div class="border-t border-gray-200 py-6">
                <label for="puttsEnabled" class="block text-sm font-medium leading-6 text-gray-900 mb-2">Would you like to calculate Strokes Gained Putting?</label>
                <Switch
                  v-model="puttsEnabled"
                  :class="puttsEnabled ? 'bg-indigo-600' : 'bg-gray-200'"
                  class="relative inline-flex h-6 w-11 items-center rounded-full"
                >
                  <span class="sr-only">Enter hole-by-hole putt distances?</span>
                  <span
                    :class="puttsEnabled ? 'translate-x-6' : 'translate-x-1'"
                    class="inline-block h-4 w-4 transform rounded-full bg-white transition"
                  />
                </Switch>
                <div v-if="puttsEnabled" class="flex flex-col gap-y-4 mt-6">
                  <div v-for="(hole, holeIndex) in roundData" :key="holeIndex">
                    <label class="block text-sm font-medium leading-6 text-gray-900">Hole {{ holeIndex + 1 }}</label>
                    <div class="flex flex-row gap-x-3">
                    <div v-for="(putt, puttIndex) in hole.putts" :key="puttIndex" >
                      <!-- <label :for="'distance-' + holeIndex + '-' + puttIndex">Distance (in feet):</label> -->
                      <input :id="'distance-' + holeIndex + '-' + puttIndex" type="number" v-model.number="putt.distance" @input="checkForNewPutt(holeIndex, puttIndex)" required placeholder="ft" class="block w-full rounded-md border-0 py-1.5 pl-3 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6 w-20">
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
                
                          </div>
                        </div>
                      </div>
                    </div>
                   
                    <div>
                      <div v-if="formStatus == 'edit'" class="px-6">
                         <transition name="fade" >
                      <div v-if="errorMsg !== ''" class="bg-red-50 border-l-4 border-l-red-400 p-4 mt-4">
                          <div class="flex">
                            <div class="flex-shrink-0">
                              <svg class="h-5 w-5 text-red-400" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"/>
                              </svg>
                            </div>
                            <div class="ml-3">
                              <p class="text-sm leading-5 text-red-700">
                                {{ errorMsg }}
                              </p>
                            </div>
                          </div>
                      </div>
                    </transition>
                      <p class="text-gray-400 text-sm mt-4">Last updated: {{ lastUpdated }}. <button @click="deleteActivity(activityId)" type="button" class="text-red-400 text-sm">Delete activity</button></p>
                    </div>
                    <div class="flex items-center px-6 py-4">
                      <button
                        v-if="formStatus == 'add'"
                        @click="addActivity"
                        class="rounded-md bg-indigo-600 w-full py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition duration-150 ease-in-out"
                      >
                        <span v-if="!performingRequest">Save Activity</span>
                        <transition name="fade">
                            <div v-if="performingRequest" class="loading">
                                <p>Loading...</p>
                            </div>
                        </transition>
                      </button>
                      <button
                        v-if="formStatus == 'edit'"
                        @click="updateActivity"
                        class="rounded-md bg-indigo-600 w-full py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 transition duration-150 ease-in-out"
                      >
                        <span v-if="!performingRequest">Update Activity</span>
                        <transition name="fade">
                            <div v-if="performingRequest" class="loading">
                                <p>Loading...</p>
                            </div>
                        </transition>
                      </button>
                    </div>
                  </div>
                  </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- Sticky search header -->
      <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8">
        <div class="flex-1 min-w-0">
        <router-link to="/share">
          <h1 class="text-lg font-medium leading-6 text-white sm:truncate">ShotSavvy</h1>
        </router-link>
      </div>

        <button @click="open = true" type="button" class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="-ml-1 mr-1 h-4 w-4" aria-hidden="true">
            <path stroke-linecap="activity" stroke-linejoin="activity" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>

          New Activity
        </button>
      </div>

    
    <!-- Heading -->
      <div class="flex flex-col items-start justify-between gap-x-8 gap-y-4 bg-gray-700/10 px-4 py-4 sm:flex-row sm:items-center sm:px-6 lg:px-8">
      <div>
      <div class="flex items-center gap-x-3">
      <div class="flex-none rounded-full bg-green-400/10 p-1 text-green-400">
      <div class="h-2 w-2 rounded-full bg-current" />
      </div>
      <h1 class="flex gap-x-3 text-base leading-7">
      <span class="font-semibold text-white">{{ greeting }}, {{userProfile ? userProfile.name.split(' ')[0] : ''}}</span>
      <!-- <span class="text-gray-600">/</span>
      <span class="font-semibold text-white">all time</span> -->
      </h1>
      </div>
      <p class="mt-2 text-xs leading-6 text-gray-400">Improve your game by being more intentional on the course.</p>
      </div>
      <!-- <div class="order-first flex-none rounded-full bg-indigo-400/10 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-400/30 sm:order-none">Production</div> -->

      </div>
      <!-- Secondary navigation -->
      <TabGroup>
        <TabList as="template">
          <div class="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 sm:px-6 lg:px-8 overflow-x-auto border-b border-white/10 py-4 bg-gray-700/10">
          <Tab as="template" v-slot="{ selected }">
            <button :class="{ 'text-indigo-400': selected, 'text-gray-400': !selected }">Performance</button>
          </Tab>
          <Tab as="template" v-slot="{ selected }">
            <button :class="{ 'text-indigo-400': selected, 'text-gray-400': !selected }">Activities</button>
          </Tab>
        </div>
        </TabList>
        <TabPanels>
          <TabPanel>
            <!-- Stats -->
            <div class="grid grid-cols-2 bg-gray-700/10 sm:grid-cols-2 lg:grid-cols-4">
            <div v-for="(stat, statIdx) in stats" :key="stat.name" :class="[statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '', 'border-t border-white/5 py-6 px-4 sm:px-6 lg:px-8']">
            <p class="text-sm font-medium leading-6 text-gray-400">{{ stat.name }}</p>
            <p class="mt-2 flex items-baseline gap-x-2">
            <span class="text-4xl font-semibold tracking-tight text-white">{{ stat.value }}</span>
            <span v-if="stat.unit" class="text-sm text-gray-400">{{ stat.unit }}</span>
            </p>
            </div>
            </div>
            <div v-if="rounds && rounds.length" class="bg-gray-700/10 w-full p-4 sm:p-6 lg:p-8 border-t border-white/5">
              <div class="mb-8">
                <div v-if="!rounds || rounds.length == 1">
                  <h3 class="font-medium text-white mb-4">Unlock performance trends when you add at least 2 rounds</h3>
                  <button @click='open = true' class="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 mb-4">Add another round</button>
                </div>
                <h3 v-else class="font-medium text-white mb-4">Performance Trend</h3>
                <div class="relative">
                <apexchart
                  height="200"
                  type="line"
                  :options="trendOptions"
                  :series="trendSeries"
                  :class="!rounds || rounds.length == 1 ? 'cursor-none blur' : ''"
                ></apexchart>
                <div v-if="!rounds || rounds.length == 1" class="absolute inset-0 flex justify-center items-center z-10 bg-gray-500/5">
                  <!-- <p class="text-2xl font-bold">This should be on top of the map</p> -->
                </div>
              </div>
              </div>
              <div>
                <h3 class="font-medium text-white">Tiger 5 Breakdown</h3>
              <apexchart
              type="donut"
              :options="chartOptions"
              :series="series"
              ></apexchart>
              </div>
            </div>

            <div v-if="!activities || !activities.length" class="sm:px-6 lg:px-8 p-8">
              <h3 class="text-white font-medium text-lg">Getting Started</h3>
              <p class="text-gray-400 mt-2">To get you started, we've detailed a few steps on how to get the most from ShotSavvy.</p>
              <ul role="list" class="divide-y divide-gray-700 mt-4">
                <li class="py-4 inline text-gray-400 flex items-center cursor-pointer" @click="open = true">
                  <PlusCircleIcon class="h-5 w-5 flex-none mr-2" aria-hidden="true" />
                  Add a new round or practice activity
                </li>
                <li class="py-4 inline text-gray-400 flex items-center">
                  <ChartPieIcon class="h-5 w-5 flex-none mr-2" aria-hidden="true" />
                  Track your performance breakdowns over time
                </li>
                <li class="py-4 inline text-gray-400 flex items-center cursor-pointer" @click="open = true">
                  <UsersIcon class="h-5 w-5 flex-none mr-2" aria-hidden="true" />
                  Share your activity with a coach or friends
                </li>
              </ul>
            </div>

          </TabPanel>

          <TabPanel>
            <div class="border-t border-white/10 py-11">
              <ul v-if="activities && activities.length" role="list" class="divide-y divide-gray-800">
                <li v-for="activity in activities" :key="activity.id"  class="px-4 sm:px-6 lg:px-8 py-5 hover:cursor-pointer" @click="editActivity(activity.id)">
                  <div class="min-w-0">
                    <div class="flex items-center gap-x-2">
                      <p class="whitespace-nowrap">
                        <time class="text-xs font-semibold leading-6 text-white">{{ formatDate(activity.date) }}</time>
                      </p>
                      <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current text-gray-400">
                        <circle cx="1" cy="1" r="1" />
                      </svg>
                    <p class="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-white" :class="activity.type != 'Practice' ? 'text-green-400 bg-green-400/10 ring-green-400/30' : 'text-gray-500 bg-gray-100/10 ring-gray-400/20'">{{ activity.type }}</p>
                    
                    </div>
                    <p v-if="activity.notes" class="rounded-md whitespace mt-0.5 py-0.5 text-xs text-gray-400 pt-2">{{activity.notes}}</p>

                    <div v-if="activity.type != 'Practice'" class="mt-3 flex items-center gap-x-2 text-xs leading-5 text-gray-400">
                      <div class="border-white/5 py-3 px-2 sm:px-3 lg:px-4 border-l">
                        <p class="text-xs font-medium leading-6 text-gray-400">Score</p>
                        <p class="mt-2 flex items-baseline justify-center gap-x-2 ">
                          <span class="text-sm font-semibold tracking-tight text-white">{{ activity.score }}</span>
                        </p>
                      </div>
                      <div class="border-white/5 py-3 px-2 sm:px-3 lg:px-4 border-l">
                        <p class="text-xs font-medium leading-6 text-gray-400">Tiger 5</p>
                        <p class="mt-2 flex items-baseline justify-center gap-x-2 ">
                          <span class="text-sm font-semibold tracking-tight text-white">{{ (activity.threePutts + activity.bogeysCount + activity.bogeys150Count + activity.failedChips + activity.doubleBogeysCount) }}</span>
                        </p>
                      </div>
                      <div class="border-white/5 py-3 px-2 sm:px-3 lg:px-4 border-x">
                        <p class="text-xs font-medium leading-6 text-gray-400">Mental</p>
                        <p class="mt-2 flex items-baseline justify-center gap-x-1">
                          <span class="text-sm font-semibold tracking-tight text-white">{{ activity.mentalErrors > 0 ? ((activity.score - activity.mentalErrors) / activity.score * 100).toFixed(0) : '100'}}</span>
                          <span class="text-xs text-gray-400">%</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>

              <div v-else class="text-left sm:px-6 lg:px-8 p-8">
                <h3 class="mt-2 text-base font-medium text-gray-300">No activities created yet</h3>
                <p class="mt-1 text-sm text-gray-400">Get started by <button @click="open = true" class="text-indigo-400">adding your first activity</button>.</p>
              </div> 
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import { ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot, TabGroup, TabList, Tab, TabPanels, TabPanel, Switch } from '@headlessui/vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { PlusCircleIcon, CheckCircleIcon, ChartPieIcon, UsersIcon } from '@heroicons/vue/24/outline'
import NumberInput from './../components/NumberInput'
import VueApexCharts from "vue3-apexcharts";
const fb = require('../firebaseConfig.js')

export default {
  setup () {
    const open = ref(false)

    return { open}
  },
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    Switch,
    NumberInput,
    XMarkIcon,
    CheckCircleIcon,
    ChartPieIcon,
    PlusCircleIcon,
    UsersIcon,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
      isLoading: true,
      performingRequest: false,
      errorMsg: '',
      formStatus: 'add',
      activityId: null,
      type: 'Tournament Round',
      score: null,
      holesPlayed: 18,
      date: new Date().toISOString().substr(0, 10),
      bogeysCount: 0,
      bogeys150Count: 0,
      doubleBogeysCount: 0,
      failedChips: 0,
      threePutts: 0,
      mentalErrors: 0,
      roundData: this.initializeRoundData(18),
      puttsEnabled: false,
      tigerFive: 0,
      notes: '',
      showNotification: false,
      chartOptions: {
        labels: ['Par 5 Bogeys', 'Bogeys Inside 150yds', 'Double Bogeys', 'Failed Chips', 'Three Putts'],
        legend: {
          show: false
        },
      },
      baselineData: {
        0: 1.00,
        1: 1.00,
        2: 1.02,
        3: 1.08
      }
    }
  },
  computed: {
	...mapState(['userProfile', 'currentUser', 'activities']),
  series () {
    let seriesArr = []
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
    if (this.activities) {
      this.activities.forEach(doc => {
      for (const key in doc) {
        if (typeof doc[key] === 'number') {
          if (key == 'bogeysCount') {
            a += doc[key]
          } else if (key == 'bogeys150Count') {
            b += doc[key]
          } else if (key == 'doubleBogeysCount') {
            c += doc[key]
          } else if (key == 'failedChips') {
            d += doc[key]
          } else if (key == 'threePutts') {
            e += doc[key]
          }
        }
      }
    })
      seriesArr.push(a, b, c, d, e)
      return seriesArr
  } else {
return seriesArr
  }

    
  },
  trendOptions () {
    let datesArr = []
    if (this.rounds) {
      this.rounds.forEach(round => {
        datesArr.push(round.date)
      })

    }

    return {
      plotOptions: {
        bar: {
          columnWidth: "10%",
          barHeight: "90%"
        }
      },
        chart: {
          toolbar: {
          show: false,
          },
        },
        grid: {
          show: false,
        },
        xaxis: {
          categories: datesArr,
          labels: {
            show: false
          },
          type: 'datetime',
          max: new Date().getTime(),
        },
        yaxis: [
        {
          seriesName: 'Scores',
          show: false,
          opposite: true,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: "Score",
          }
        },
        {
          seriesName: 'Tiger 5',
          show: false,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: "Tiger 5"
          },
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          }
        },
        {
          seriesName: 'Mental Errors',
          show: false,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false,
          },
          title: {
            text: "Mental Errors"
          },
          labels: {
            formatter: function(val) {
              return val.toFixed(0);
            }
          }
        }
        ],
        legend: {
          show: false
        },
        dataLabels: {
          enabled: false
        },
      }
  },
  trendSeries () {
    let tigerFiveArr = []
    let mentalErrorsArr = []
    let scoresArr = []
    let datesArr = []
    // Calculate the series arrays
    if (this.rounds) {
      this.rounds.forEach(round => {
        tigerFiveArr.push(round.tigerFive)
        mentalErrorsArr.push(round.mentalErrors)
        scoresArr.push(round.score)
        datesArr.push(round.date)
      })

    }

    // Return the series
    return [
      {
        name: "Score",
        type: 'line',
        data: scoresArr
      },
      {
        name: 'Tiger 5',
        type: 'column',
        data: tigerFiveArr
      },
      {
        name: "Mental Errors",
        type: 'column',
        data: mentalErrorsArr
      },
    ]
  },
  rounds () {
    if (this.activities) {
      let rounds = this.activities.filter(activity => {
        return activity.type != 'Practice'
      })
      return rounds.reverse()
    } else {
      return []
    }
    
  },
  stats () {
    let statsArr = []
    if (this.rounds) {
      let sum = 0;
      let count = 0;
      let tigerArr = []
      let mentalErrorsSum = 0
      this.rounds.forEach(doc => {
      let tiger = 0
      for (const key in doc) {
        if (typeof doc[key] === 'number') {

          if (key == 'score') {
            sum += doc[key]
          } else if (key == 'mentalErrors') {
            mentalErrorsSum += doc[key]
          } else if (key == 'tigerFive') {
            tiger += doc[key]
          }
        }
      }
      tigerArr.push(tiger)
      count++
    })
      let tigerSum = 0
      tigerArr.forEach( num => {
        tigerSum += num;
      })
      // console.log(count, sum)
      const averageScore  = count > 0 ? (sum / count).toFixed(1) : 0;
      const averageTiger = count > 0 ? (tigerSum / count).toFixed(1) : 0;
      const averageMental = count > 0 ? (mentalErrorsSum / count).toFixed(1) : 0;
      // console.log(averageScore, averageTiger, averageMental)
      statsArr.push(
        {name: 'Tiger 5', value: averageTiger, unit: '/round'},
        {name: 'Mental Errors', value: averageMental, unit: '/round'},
        {name: 'Avg Score', value: averageScore},
        {name: 'Rounds', value: count})
      return statsArr
    } else {
      return statsArr
    }
    
  },
	greeting () {
		let myDate = new Date();
		let hrs = myDate.getHours();

		let greet;

		if (hrs < 12)
			greet = 'Good Morning';
		else if (hrs >= 12 && hrs <= 17)
			greet = 'Good Afternoon';
		else if (hrs >= 17 && hrs <= 24)
			greet = 'Good Evening';
		return greet
	}
  },
  created() {
    document.addEventListener('swUpdated', this.updateAvailable, { once: true })
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      // We'll also need to add 'refreshing' to our data originally set to false.
      if (this.refreshing) return
      this.refreshing = true
      // Here the actual reload of the page occurs
      window.location.reload()
    })
  },
  mounted () {
    if (location.hostname !== "localhost") {
      window.analytics.page('Home')
    }
	},
  watch: {
    holesPlayed(newVal) {
      this.roundData = this.initializeRoundData(newVal);
    }
  },
  methods: {
    updateAvailable(event) {
      console.log(event)
      this.registration = event.detail
      this.updateExists = true
    },
    refreshApp() {
      this.updateExists = false
      // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // Send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    resetDialog() {
      this.formStatus = 'add'
      this.type = 'Tournament Round'
      this.score = null
      this.holesPlayed = 18
      this.date = new Date().toISOString().substr(0, 10)
      this.bogeysCount = 0
      this.bogeys150Count = 0
      this.doubleBogeysCount = 0
      this.failedChips = 0
      this.threePutts = 0
      this.tigerFive = 0
      this.mentalErrors = 0
      this.roundData = this.initializeRoundData(18)
      this.notes = ''
      this.activityId = 0
      this.puttsEnabled = false
      this.open = false
      this.lastUpdated = null
      this.performingRequest = false
    },
    formatDate(isoString) { 
      function getOrdinalSuffix(day) { 
        if (day > 3 && day < 21) 
          return 'th'; 
        switch (day % 10) { 
          case 1: return 'st'; 
          case 2: return 'nd'; 
          case 3: return 'rd'; 
          default: return 'th'; 
        } 
      }
      const localDate = new Date(isoString); 
      localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());

      const day = localDate.getDate(); 
      const ordinalSuffix = getOrdinalSuffix(day); 
      const options = { 
        year: 'numeric', 
        month: 'long' 
      }; 

      const formattedDate = localDate.toLocaleDateString('en-US', options); 
      return `${formattedDate.split(' ')[0]} ${day}${ordinalSuffix}, ${formattedDate.split(' ')[1]}`; 
    },
    addHole() {
      this.roundData.push({ putts: [{ distance: 0 }] });
    },
    checkForNewPutt(holeIndex, puttIndex) {
      const hole = this.roundData[holeIndex];
      if (hole.putts[puttIndex].distance > 0 && puttIndex === hole.putts.length - 1) {
        hole.putts.push({ distance: 0 });
      }
    },
    initializeRoundData(holesPlayed) {
      return Array.from({ length: holesPlayed }, () => ({ putts: [{ distance: 0 }] }));
    },
    editActivity(id) {
      this.formStatus = 'edit'
      this.open = true
      
      const activity = this.activities.find(activity => activity.id === id);
      console.log(activity.roundData)
      this.type = activity.type
      this.score = activity.score
      this.holesPlayed = activity.holesPlayed
      this.date = activity.date
      this.bogeysCount = activity.bogeysCount
      this.bogeys150Count = activity.bogeys150Count
      this.doubleBogeysCount = activity.doubleBogeysCount
      this.failedChips = activity.failedChips
      this.threePutts = activity.threePutts
      this.mentalErrors = activity.mentalErrors
      this.notes = activity.notes
      this.activityId = activity.id
      this.tigerFive = activity.tigerFive
      this.roundData = activity.roundData
      this.puttsEnabled = activity.puttsEnabled
      let date = new Date(Number(activity.lastUpdated))
      this.lastUpdated = date.getDate() + '/' +  date.getMonth() + '/' + date.getFullYear();

    },
    deleteActivity (id) {
        let _this = this
        // Create batch action
        this.performingRequest = true
        fb.activitiesCollection.doc(id).delete().then(function() {
            console.log("Document successfully deleted!");
            _this.$store.dispatch('fetchActivities')
            _this.resetDialog()

        }).catch(function(error) {
            console.error("Error removing document: ", error);
            this.performingRequest = false
            this.errorMsg = error.message
        });
      },
    updateActivity() {
      try  {
        let _this = this
        if (this.type != 'Practice' && this.date && this.score || this.type == 'Practice' && this.date && this.notes.length > 5) {
          this.performingRequest = true
          fb.activitiesCollection.doc(this.activityId).update({
            uid: this.currentUser.uid,
            score: this.score,
            holesPlayed: this.holesPlayed,
            date: this.date,
            bogeysCount: this.bogeysCount,
            bogeys150Count: this.bogeys150Count,
            doubleBogeysCount: this.doubleBogeysCount,
            failedChips: this.failedChips,
            threePutts: this.threePutts,
            mentalErrors: this.mentalErrors,
            roundData: this.roundData,
            tigerFive: this.bogeysCount + this.bogeys150Count + this.doubleBogeysCount + this.failedChips + this.threePutts,
            notes: this.notes
          }).then((res) => {
            console.log("Document updated", res)
            _this.$store.dispatch('fetchActivities')
            _this.resetDialog()
            this.showNotification = true
            setTimeout(() => _this.showNotification = false, 3000);
          }).catch(err => {
            console.log(err)
            this.performingRequest = false
            this.errorMsg = err.message
          })
        }
      } catch (error) {
        console.log(error)
        this.performingRequest = false
        this.errorMsg = error.message
      }
    },
    addActivity() {

      // Create a timestamp
      let timestamp = Date.now().toString()
      
      try  {
        if (this.type != 'Practice' && this.date && this.score || this.type == 'Practice' && this.date && this.notes.length > 5) {
          this.performingRequest = true
          fb.activitiesCollection.add({
            uid: this.currentUser.uid,
            type: this.type,
            score: this.score,
            holesPlayed: this.holesPlayed,
            date: this.date,
            bogeysCount: this.bogeysCount,
            bogeys150Count: this.bogeys150Count,
            doubleBogeysCount: this.doubleBogeysCount,
            failedChips: this.failedChips,
            threePutts: this.threePutts,
            puttsEnabled: this.puttsEnabled,
            roundData: this.roundData,
            tigerFive: this.bogeysCount + this.bogeys150Count + this.doubleBogeysCount + this.failedChips + this.threePutts,
            mentalErrors: this.mentalErrors,
            notes: this.notes,
            lastUpdated: timestamp
          }).then((res) => {
            console.log("Document written with ID: ", res.id)
            if (location.hostname !== "localhost") {
              // Track New activity Added
              window.analytics.track('Activity Added', {
                email: this.currentUser.email,
                createdAt: timestamp,
                type: this.type
              });
            }
            this.$store.dispatch('fetchActivities')
            this.resetDialog()
            this.showNotification = true
            this.performingRequest = false
            setTimeout(() => this.showNotification = false, 3000);
          }).catch(err => {
            console.log(err)
            this.performingRequest = false
            this.errorMsg = err.message
          })
        }
      } catch (error) {
        console.log(error)
        this.performingRequest = false
        this.errorMsg = error.message
      }
    },
    calculateStrokesGained(userPutts) {
      let totalStrokesGained = 0;
      userPutts.forEach(putt => {
      const expectedPutts = this.baselineData[putt.distance];
      if (expectedPutts) {
      const strokesGained = expectedPutts - putt.strokes;
      totalStrokesGained += strokesGained;
      }
      });
      // totalStrokesGained = calculateStrokesGained(userPutts, baselineData);
      console.log("Total Strokes Gained/Lost:", totalStrokesGained);
      return totalStrokesGained;
      },

      
  },
}
</script>