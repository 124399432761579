import { createApp } from 'vue'
import { store } from './store'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import './index.css'
import './registerServiceWorker'

Sentry.init({
  createApp,
  dsn: "https://e1fbc665f6798da71a81d7e4fed223a1@o4506980146216960.ingest.us.sentry.io/4506980308811776",
  environment: window.location.hostname != "localhost" ? 'production' : 'development',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  trackComponents: true,
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

createApp(App).use(store).use(router).mount('#app')