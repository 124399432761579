import { createRouter, createWebHistory } from 'vue-router'
import firebase from 'firebase/compat/app'
import HomePage from '../views/HomePage.vue'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginPage.vue')
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SignupPage.vue')
  },
  {
    path: '/share',
    name: 'Share',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SharePage.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/share/:id',
    name: 'SharedUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SharedUserPage.vue'),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: {
      authRequired: true,
    },
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

function AuthUser() {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
}

router.beforeEach(async (to) => {
  const canAccess = await AuthUser();

  if (!canAccess && to.meta.authRequired && to.name !== 'Login') {
    return { name: "Login" };
  } 
  
});

export default router
